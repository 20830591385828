import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Header = ({ language, setLanguage, fontFamilyStyle }) => {
  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    setLanguage(lang);
    localStorage.setItem("language", lang);
    fontFamilyStyle(lang === "Arabic" ? "Aref Ruqaa, serif" : "Proxima Soft");
  };
  return (
    <header className="page-header px-md-4 py-md-3 py-2">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-md" style={{ fontFamily: fontFamilyStyle }}>
          {/* Mobile Navigation */}
          <div className="mobile_nav d-md-none">
            <button
              className="btn toogle_btn d-md-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <span>
                <img src="assets/imges/menu.svg" alt="menuicon" />
              </span>
            </button>
          </div>

          {/* Offcanvas Menu */}
          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title yellow" id="offcanvasExampleLabel">
                <a href="index.html">
                  <img src="assets/imges/logo.svg" alt="logo" width={62} />
                </a>
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>

            {/* Language-specific menu */}
            {language === "English" ? (
              <div className="offcanvas-body">
                <nav className="navbar menubar">
                  <ul className="navbar-nav">
                    <li className="nav-item"><a className="nav-link active" href="/#home">Home</a></li>
                    <li className="nav-item"><a className="nav-link" href="/#Features">Features</a></li>
                    <li className="nav-item"><a className="nav-link" href="https://dev.school.khatt.app/sign_up/en">School</a></li>
                    <li className="nav-item"><Link className="nav-link" to="/blog">Blog</Link></li>
                    <li className="nav-item"><a className="nav-link" href="/#">Download</a></li>
                    <li className="nav-item"><a className="nav-link" href="/#Contcat-us">Contact us</a></li>
                  </ul>
                </nav>
              </div>
            ) : (
              <div className="offcanvas-body">
                <nav className="navbar menubar">
                  <ul className="navbar-nav">
                    <li className="nav-item"><a className="nav-link" href="/#Features">الميزات</a></li>
                    <li className="nav-item"><a className="nav-link" href="https://dev.school.khatt.app/sign_up/ar">المدارس</a></li>
                    <li className="nav-item"><Link className="nav-link" to="/blog">مدونة</Link></li>
                    <li className="nav-item"><a className="nav-link" href="/#">تحميل</a></li>
                    <li className="nav-item"><a className="nav-link" href="/#Contcat-us">اتصل بنا</a></li>
                    <li className="nav-item"><a className="nav-link active" href="/#home">الواجهة</a></li>
                  </ul>
                </nav>
              </div>
            )}
          </div>

          {/* Language Select */}
          <div className="ms-auto me-2">
            <select
              className="form-select"
              value={language}
              onChange={handleLanguageChange}
              aria-label="Language select"
            >
              <option value="Arabic">عربي</option>
              <option value="English">English</option>
            </select>
          </div>

          <a className="navbar-brand" href="index.html">
            <img src="assets/imges/logo.svg" alt="logo" className="img-fluid" />
          </a>
        </nav>
      </div>

      {/* Video Modal */}
      <div
        className="modal fade"
        id="vedio_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="text-end p-2">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <video width="100%" height={500} controls style={{ backgroundColor: "black" }}>
              <source
                src="https://khatappdev.s3.me-south-1.amazonaws.com/video/Khat-App.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
