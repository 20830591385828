import Header from "../component/Nevbar";
import Footer from "../component/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from "../component/Pagination";
import Spinner from "../component/Spinner";

const Blog = () => {
  const [language, setLanguage] = useState("Arabic");
  const [fontFamilyStyle, setFontFamily] = useState("Proxima Soft");
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [blogCount, setBlogCount] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  // Effect to add sticky class on scroll
  useEffect(() => {
    const header = document.querySelector(".page-header");
    const toggleClass = "is-sticky";

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 150) {
        header.classList.add(toggleClass);
      } else {
        header.classList.remove(toggleClass);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Effect to set the default language from local storage on initial load
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "Arabic";
    setLanguage(savedLanguage);
    setFontFamily(
      savedLanguage === "Arabic" ? "Aref Ruqaa, serif" : "Proxima Soft"
    );
  }, []);

  // Effect to fetch blog data from API
  useEffect(() => {
    setLoading(true);
    axios
      .post(
        "https://reactapi.khatt.app/api/v1/school/common/get_blog_web",
        // "http://localhost:4000/api/v1/school/common/get_blog_web",
        {
          page: page,
          per_page: 9,
          search: searchTerm,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "QvDRU0lXzsx8qdoU3Ryv1g==",
          },
        }
      )
      .then((response) => {
      console.log('response :', response);
        setBlogs(response.data.data);
        setBlogCount(response.data.blog_count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("An error occurred while fetching the blogs.");
      });
  }, [page, searchTerm]);

  const handleReadMore = (blog) => {
    navigate(`/blog_details`, { state: { blog } });
  };

  return (
    <>
      <Header
        language={language}
        setLanguage={setLanguage}
        fontFamilyStyle={setFontFamily}
      />
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-3 mb-4">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={
                    language === "Arabic" ? "يبحث...." : "Search...."
                  }
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Replace the default loading indicator with the Spinner component */}
          {loading && <Spinner message="Loading blogs..." isActive={loading} />}
          {error && <p>{error}</p>}

          {/* Render blogs */}
          <div className="row">
            {blogs.length === 0 ? (
              <h1 className="text-center w-100" style={{ color: "white" }}>
                No blogs found
              </h1>
            ) : (
              blogs.map((blog) => (
                <div key={blog.id} className="col-lg-4 col-md-6 col-sm-6 mb-4">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img src={blog.blog_image} alt="image" />
                    </div>
                    {language == "Arabic" ? (
                      <div
                        className="blog-details"
                        style={{
                          direction: "rtl",
                          fontFamily: fontFamilyStyle,
                        }}
                      >
                        <p className="text-white mb-3">
                          <i className="fa-regular fa-calendar-days pe-2"></i>
                          {language === "Arabic"
                            ? new Date(blog.insertdate).toLocaleDateString(
                                "ar-EG"
                              )
                            : new Date(blog.insertdate).toLocaleDateString()}
                        </p>
                        <h4
                          className="title"
                          style={{ fontFamily: fontFamilyStyle }}
                        >
                          {blog.ar_title}
                        </h4>
                        <p>{blog.excerpt}</p>
                        <button
                          className="invite_btn blue_bg text-white mt-3"
                          onClick={() => handleReadMore(blog)}
                        >
                          {language == "Arabic" ? "اقرأ المزيد" : "Read more"}
                        </button>
                      </div>
                    ) : (
                      <div
                        className="blog-details"
                        style={{ fontFamily: fontFamilyStyle }}
                      >
                        <p className="text-white mb-3">
                          <i className="fa-regular fa-calendar-days pe-2"></i>
                          {language === "Arabic"
                            ? new Date(blog.insertdate).toLocaleDateString(
                                "ar-EG"
                              )
                            : new Date(blog.insertdate).toLocaleDateString()}
                        </p>
                        <h4 className="title">{blog.title}</h4>
                        <p>{blog.excerpt}</p>
                        <button
                          className="invite_btn blue_bg text-white mt-3"
                          onClick={() => handleReadMore(blog)}
                        >
                          Read more
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
            <Pagination
              per_page={5}
              pageCount={blogCount}
              onPageChange={handlePageChange}
              page={page}
              lableName={`Blog`}
            />
          </div>
        </div>
      </section>

      <Footer language={language} />
    </>
  );
};

export default Blog;
