import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Footer = ({ language }) => {
  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    // Get the current year when the component mounts
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <>
      <Helmet>
        {/* You can remove the script as it's not needed */}
      </Helmet>
      <footer>
        <p className="text-white text-center py-4 fw_4">
          {language === "Arabic"
            ? `©${currentYear} خاتم. جميع الحقوق محفوظة.`
            : `©${currentYear} Khat. All rights reserved.`}
        </p>
      </footer>
    </>
  );
};

export default Footer;
